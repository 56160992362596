import React, {useState} from "react";

const CustomSelectWithTwoListsForPorts = ({searchInput, setSearchInput, firstState, firstListData, secondList, firstList, setFirstList, setSelectedInput}) => {
    const [showDropdown, setShowDropdown] = useState(false);

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            setSelectedInput(null);
            setSearchInput("");
            setFirstList(firstListData);
        }
    };

    const handleSelect = (port) => {
        setSelectedInput(port);
        setSearchInput(port.label);
    };

    const handleSearchInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input);

        const filtered = firstState ? firstListData : secondList.filter(
            (port) => port.label.toLowerCase().includes(input)
        );
        setFirstList(filtered);
    };

    return (
        <div className="custom-select flex--row align-items--center">
            <input
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
                onFocus={() => {
                    setShowDropdown(true);
                    setFirstList(firstState ? firstListData : secondList);
                }}
                onBlur={() => setShowDropdown(false)}
                onKeyDown={handleKeyDown}
                placeholder="Søk etter port..."
                className="search-input"
            />
            {showDropdown && (
                <div className="dropdown-list">
                    {(firstState ? firstList : secondList).map((item, index) => (
                        <p
                            key={index}
                            className={`dropdown-item ${item.taken ? "disabled" : ""}`}
                            onMouseDown={() => !item.taken && handleSelect(item)}
                        >
                            {item.label}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}
export default CustomSelectWithTwoListsForPorts;