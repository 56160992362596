import GetById from "../apiCalls/Get/GetById";
import HandleNotification from "../notifications/HandleNotification";

const SaveUpdateCoordinates = async (updatingId, updatingEndpoint, isUpdatingLine, isUpdatingPoint, isUpdatingPolygon, clickedCoordinate, updatedCoordinates, setDatabaseUpdateCounter, setIsUpdatingLine, setIsUpdatingPoint, setIsUpdatingPolygon, setUpdatedCoordinates, setUpdatingId, id, update, portData) => {
    try {
        if (!updatingId || !updatingEndpoint) {
            console.error('Invalid id or endpoint');
            return;
        }
        let bodyData;
        if (isUpdatingLine) {
            if (portData) {
                console.log("sender med port data");
                bodyData = {
                    nodeId: portData.nodeId,
                    portConnections: portData.portConnections,
                    updatedCoordinatesList: updatedCoordinates.map(coord => [coord[1], coord[0]]),
                }
                console.log(bodyData);
            }
            else {
                bodyData = {
                    updatedCoordinatesList: updatedCoordinates.map(coord => [coord[1], coord[0]]),
                };
            }
        } else if (isUpdatingPoint) {
            const [latitude, longitude] = clickedCoordinate;
            bodyData = {
                coordinates: {
                    latitude,
                    longitude
                },
            };
        } else if (isUpdatingPolygon) {
            bodyData = {
                updatedCoordinatesList: [updatedCoordinates.map(coord => [coord[1], coord[0]])],
            };
        }
        else {
            console.error('Invalid operation: Neither updating point, line or polygon');
            return;
        }
        let response = await update(updatingId, updatingEndpoint, bodyData);
        if (response) {

            setIsUpdatingPoint(false);
            setIsUpdatingLine(false);
            setIsUpdatingPolygon(false)
            setUpdatedCoordinates([]);
            setUpdatingId(null);
            setDatabaseUpdateCounter(prevCounter => prevCounter + 1);
            
            const trimmedEndpoint = updatingEndpoint.replace('/update-coordinates', '');
            const updatedData = await GetById(trimmedEndpoint, updatingId);
            await HandleNotification(updatedData, 'Endret koordinatene til ', updatedData.properties ? updatedData.properties.name : updatedData.name);
        }
        else {
            console.error('Error updating coordinates');
        }
    } catch (error) {
        console.error('Error updating coordinates:', error);
    }
};

export default SaveUpdateCoordinates;