import {useEffect, useState} from "react";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../../configs/api";
import Feedbacks from "../../../containers/Feedbacks";

const UserViewFeedback = () => {
    const [feedback, setFeedback] = useState([]);

    useEffect(() => {
        const fetchFeedback = async () => {
            const feedback = await GetAll(apiUrls.get.userFeedback);
            if (feedback) {
                setFeedback(feedback);
            }
        }
        fetchFeedback();
    }, []);
    return (
        <div className="profile--display-body">
            <Feedbacks feedbacks={feedback} showUsername={false} users={null}/>
        </div>
    );
}
export default UserViewFeedback;