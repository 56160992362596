import React, { useEffect, useState } from 'react';
import statusOptions from '../../../../assets/data/jsonFiles/General/statuses.json';
import getAllNodesPorts from '../../../../functions/extractors/getAllNodesPorts';
import getAvailablePorts from '../../../../functions/extractors/getAvailablePorts';
import SelectWithData from '../../../inputsSelectsCheckboxes/SelectWithData';
import InfoMessages from "../../../messagesAndPopups/InfoMessages";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../../configs/api";
import CustomSelectWithTwoListsForPorts from "../../../inputsSelectsCheckboxes/custom/CustomSelectWithTwoListsForPorts";
import CustomSelect from "../../../inputsSelectsCheckboxes/custom/CustomSelect";

const NewCableInputs = ({ handleInputChange, handlePipeChange, currentGroupIndex, pipesData, formData, handleNodeChange, nodesData, setFormData }) => {
    const [cableTypes, setcableTypes] = useState(null);
    const [addPorts, setAddPorts] = useState(false);
    const [removePorts, setRemovePorts] = useState(false);

    const [ports, setPorts] = useState([]);
    const [addedPorts, setAddedPorts] = useState([]);

    const [selectedPort, setSelectedPort] = useState(null);
    const [searchInputPorts, setSearchInputPorts] = useState("");
    const [filteredNodePorts, setFilteredNodePorts] = useState([]);
    const [searchInputNodes, setSearchInputNodes] = useState("");
    const [filteredNodes, setFilteredNodes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const typesResponse = await  GetAll(apiUrls.get.cableTypes);
            if (typesResponse) {
                setcableTypes(typesResponse);
                setFormData(prevFormData => ({
                    ...prevFormData,
                    cableType: typesResponse[0]?.name || ''
                }));
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (formData.connectToNode === false && !formData.selectedNode && nodesData && nodesData.length > 0) {
            handleNodeChange('selectedNode', nodesData[0].id);
        }
    }, [nodesData, formData.connectToNode]);

    const getPipesOptions = () => {
        return pipesData.map(pipe => ({
            value: pipe.id,
            label: pipe.properties.name,
            full: pipe.properties.filled,
            tempClosed: pipe.properties.status === "Midlertidig stengt/Under vedlikehold"
        }));
    };

    const getNodesOptions = () => {
        return nodesData.map(node => ({
            value: node.id,
            label: node.name,
            full: getAvailablePorts(node) === 0,
            tempClosed: node.status === "Midlertidig stengt/Under vedlikehold"
        }));
    };

    useEffect(() => {
        const getPorts = async () => {
            if (!formData.selectedNode) return;
            const ports = await getAllNodesPorts(formData.selectedNode.id);
            setPorts(ports);
            setFilteredNodePorts(ports);
        };
        getPorts();
    }, [formData.selectedNode]);


    const handleStateChange = (setDisplay) => {
        setDisplay(prevDisplay => !prevDisplay);
        setSelectedPort(null);
        setSearchInputPorts("")
    }

    const handleUpdatePort = async () => {
        try {
            if (!selectedPort) return;
            const labelMatch = selectedPort.label.match(/Switch (\d+), Port (\d+)/);
            if (!labelMatch) {
                console.error("Invalid label format:", selectedPort.label);
                return;
            }
            const switchNum = parseInt(labelMatch[1], 10);
            const portNum = parseInt(labelMatch[2], 10);
            if (addPorts) {
                setAddedPorts((prevAddedPorts) => [...prevAddedPorts, selectedPort]);
                const newPortConnection = { switchNum, portNum };
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    portConnections: [...prevFormData.portConnections, newPortConnection],
                }));
            } else if (removePorts) {
                setAddedPorts((prevAddedPorts) =>
                    prevAddedPorts.filter((port) => port.label !== selectedPort.label)
                );
                const updatedPortConnections = formData.portConnections.filter(
                    (connection) => connection.portNum !== portNum
                );
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    portConnections: updatedPortConnections,
                }));

                if (addPorts.length > 0) {
                    setRemovePorts(false);
                }
            }
            setSelectedPort(null);
            setSearchInputPorts("");
            setFilteredNodePorts(ports);
        } catch (e) {
            console.error('Error in handleUpdatePort:', e);
        }
    };

    return (
        <div className="map--add-form-inputs flex--column">
            {currentGroupIndex === 0 && (
                <>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Navn</label>
                            <input
                                type='text'
                                id='name'
                                name='name'
                                placeholder='Kabelnavnet'
                                value={formData.name}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Kabel Type</label>
                            <select
                                name="cableType"
                                id="cableType"
                                value={formData.cableType}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            >
                                {cableTypes && (
                                    cableTypes.length === 0 ? (
                                        <option value="">
                                            Ingen typer opprettet
                                        </option>
                                    ) : (
                                        cableTypes.map((option, index) => (
                                            <option key={index} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))
                                    )
                                )}
                            </select>
                        </div>
                        <div className='flex--column map--add-form-input-container'>
                            <label>Status</label>
                            <SelectWithData
                                data={statusOptions.statusOptions}
                                name="status"
                                id="status"
                                value={formData.status}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className='flex--column whole-row'>
                            <label>Notater</label>
                            <input
                                type='text'
                                id='notes'
                                name='notes'
                                placeholder='Viktig tilleggsinformasjon'
                                value={formData.notes}
                                onChange={(e) => handleInputChange(e.target.name, e.target.value, setFormData)}
                            />
                        </div>
                    </div>
                </>
            )}

            {currentGroupIndex === 1 && (
                <>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        <div className="flex--column map--add-form-input-container">
                            <label>I Rør</label>
                            <SelectWithData
                                data={getPipesOptions()}
                                name="selectedPipe"
                                id="selectedPipe"
                                value={formData.selectedPipe}
                                onChange={handlePipeChange}
                                extraOption={true}
                                extraOptionLabel="Ikke i rør"
                            />
                        </div>
                        {formData.selectedPipe && (
                            <div className="flex--column map--add-form-input-container">
                                <label>Bruk rørets kooridnater?</label>
                                <div className='checkbox flex--column justify-content-center'>
                                    <input
                                        name='usePipesCoordinates'
                                        id='usePipesCoordinates'
                                        type='checkbox'
                                        checked={formData.usePipesCoordinates}
                                        onChange={(e) => handleInputChange(e.target.name, e.target.checked, setFormData)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        {getNodesOptions().length > 0 && (
                            <>
                                <div className='flex--column map--add-form-input-container'>
                                    <label>
                                        Koble til node og porter
                                    </label>
                                    <div className='checkbox flex--column justify-content-center'>
                                        <input
                                            name='connectToNode'
                                            id='connectToNode'
                                            type='checkbox'
                                            checked={formData.connectToNode}
                                            onChange={(e) => handleInputChange(e.target.name, e.target.checked, setFormData)}
                                            disabled={!getNodesOptions().length > 0}
                                        />
                                    </div>
                                </div>
                                <div className='flex--column map--add-form-input-container message'>
                                    <InfoMessages type='info'
                                                  message='La stå huket av om du ikke vil koble kabelen til en node og porter.'/>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='flex--row justify-content-sb map--add-form-input-row'>
                        {formData.connectToNode && (
                            <div className="flex--column map--add-form-input-container">
                                <label>Velg Node</label>
                                <CustomSelect
                                    searchInput={searchInputNodes}
                                    setSearchInput={setSearchInputNodes}
                                    searchResults={filteredNodes}
                                    setSearchResults={setFilteredNodes}
                                    searchData={formData.connectToNode ? getNodesOptions() : []}
                                    name={"selectedNode"}
                                    handleNodeChange={handleNodeChange}
                                />
                            </div>
                        )}
                    </div>
                    <div className='map--add-ports flex--column map--add-form-input-row'>
                        <label>Valgte Porter</label>
                        {formData.connectToNode && (
                            <div className="flex--row justify-content-sb">
                                    <div className="map--add-ports-display flex--row align-items--center">
                                        {addedPorts && addedPorts.length > 0 ? (
                                                addedPorts.map((port, index) => (
                                                    <p key={index} className="flex--row align-items--center">
                                                        {port.label} {index < addedPorts.length - 1 ? ' | ' : ''}
                                                    </p>
                                                ))
                                            )
                                            : (
                                                <p>Du har ikke valgt noen porter</p>
                                            )}
                                    </div>
                                    <div className="flex--row ">
                                        {(addPorts || removePorts) && (
                                            <div className="map--add-ports-select flex--row align-items--center">
                                                <CustomSelectWithTwoListsForPorts
                                                    searchInput={searchInputPorts}
                                                    setSearchInput={setSearchInputPorts}
                                                    firstState={addPorts}
                                                    firstListData={ports}
                                                    secondList={addedPorts}
                                                    firstList={filteredNodePorts}
                                                    setFirstList={setFilteredNodePorts}
                                                    setSelectedInput={setSelectedPort}
                                                />
                                                <div className="flex--row align-items--center">
                                                    {(addedPorts || (removePorts && addedPorts.length > 0)) && (
                                                        <button
                                                            type="button"
                                                            className={`btn btn-${addPorts ? 'secondary' : 'delete'}-white-bg m-l-10px ${selectedPort ? '' : 'disabled'} `}
                                                            onClick={() => handleUpdatePort()}
                                                            disabled={!selectedPort}
                                                        >
                                                            {addPorts ? 'Legg Til' : 'Fjern'}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>

                                        )}
                                        <div className="map--add-ports-buttons flex--row align-items--center">
                                            {!removePorts && (
                                                <button
                                                    type="button"
                                                    className={`btn btn-primary-white-bg ${addPorts ? 'active' : ''} m-l-10px `}
                                                    onClick={() => handleStateChange(setAddPorts)}
                                                >
                                                    {addPorts ? 'Ferdig' : 'Legg Til'}
                                                </button>
                                            )}
                                            {!addPorts  && (
                                                <button
                                                    type="button"
                                                    className={`btn ${removePorts ? 'btn-primary-white-bg active' : 'btn-delete-white-bg'} m-l-10px `}
                                                    onClick={() => handleStateChange(setRemovePorts)}
                                                >
                                                    {removePorts ? 'Ferdig' : 'Fjern'}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default NewCableInputs;


