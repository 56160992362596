import firstLetterUpper from "../../functions/formats-and-regex/FirstUpperCase";
import FormatDate from "../../functions/formats-and-regex/formatDate";
import React from "react";

const Feedbacks = ({feedbacks, showUsername, users}) => {
    return (
        <>
            <div className="feedback--header flex--row justify-content-sb">
                <p>Type</p>
                <p>Melding</p>
                {showUsername && (
                    <p>Bruker</p>
                )}
                <p>Versjon</p>
                <p>Dato</p>
            </div>

            <div className="feedback--data flex--column">
                {feedbacks && feedbacks.length > 0 ? (
                        feedbacks.slice().reverse().map((feedback, index) => {
                            let user = "";
                            if (showUsername) {
                                user = users.find(user => user && user.id === feedback.userId);
                            }
                            return (
                                <div key={index}
                                     className={`feedback ${feedback.type} br flex--row align-items--center justify-content-sb`}>
                                    <p>{firstLetterUpper(feedback.type)}</p>
                                    <p>{feedback.message}</p>
                                    {showUsername && user && <p>{user.username}</p>}
                                    <p>{feedback.version}</p>
                                    <p>{FormatDate(feedback.date)}</p>
                                </div>
                            );
                        }))
                    : (
                        <div className="feedback br flex--row justify-content-sb align-items--center">
                            <p className='feedback-none'>Ingen {!showUsername && ('sendte')} tilbakemeldinger foreløping</p>
                        </div>
                    )
                }
            </div>
        </>
    );
}
export default Feedbacks;