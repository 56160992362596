import React, { useEffect, useState } from 'react';
import apiUrls from "../../../../configs/api";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";
import GetById from "../../../../functions/apiCalls/Get/GetById";
import Feedbacks from "../../../containers/Feedbacks";

const ViewFeedback = () => {
    const [feedbacks, setFeedbackData] = useState([]);
    const [users, setUsersData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const feedbackData = await GetAll(apiUrls.developer.feedback);
            if (feedbackData) {
                setFeedbackData(feedbackData);
                const userIds = feedbackData.map(feedback => feedback.userId);
                const users = await Promise.all(userIds.map(userId => GetById(apiUrls.get.users, userId)));
                setUsersData(users);
            }
        };
        fetchData();
    }, []);

    return (
        <div className="profile--display-body">
            <Feedbacks feedbacks={feedbacks} showUsername={true} users={users} />
        </div>
    );
};

export default ViewFeedback;
