import React from "react";
import Delete from "../../functions/apiCalls/Delete";
import apiUrls from "../../configs/api";

const DeleteUserPopup = ({user, setShowDeletePopup, setData}) => {
    const confirmDelete = async (objectId) => {
        try {
            await deleteObject();
            setData(prevData => prevData.filter(item => item.id !== objectId));
        } catch (error) {
            console.error('Error during deletion:', error);
        } finally {
            setShowDeletePopup(false);
        }
    };

    const deleteObject = async () => {
        try {
            await Delete(apiUrls.admin.deleteUser, user.id);
        } catch (error) {
            console.error('Error during deletion:', error);
        }
    };

    if (!user) {
        return <div><p>Loading...</p></div>;
    }

    return (
        <div className={`delete-popup popup-opacity-background flex--column justify-content-center align-items--center align-text--center`}>
            <div className="delete-popup-content flex--column justify-content-center align-text--center">
                <h3>Du sletter nå brukeren {user.username}</h3>
                <p>Er du sikker på at du vil slette brukeren? Denne handlingen kan ikke angres!</p>
                <div className="flex--row justify-content-center">
                    <button
                        className="btn btn-delete-white-bg"
                        onClick={() => confirmDelete(user.id)}
                    >
                        Slett
                    </button>
                    <button
                        className="btn btn-primary-white-bg"
                        onClick={() => setShowDeletePopup(false)}
                    >
                        Avbryt
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteUserPopup;