import React, {useEffect, useState} from "react";
import Update from "../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../configs/api";
import nodeStatuses from "../../../../assets/data/jsonFiles/Nodes/node_statuses.json";
import nodeTypes from "../../../../assets/data/jsonFiles/Nodes/node-types.json";
import HandleNotification from "../../../../functions/notifications/HandleNotification";
import InfoMessage from "../../../messagesAndPopups/InfoMessages";

const InfoPageEditNodeContents = ({node, newName, setEditMode}) => {
    const userId = sessionStorage.getItem('userId');
    const [editedNode, setEditedNode] = useState({
        name: newName || '',
        status: node.status || '',
        nodeType: node.nodeType || '',
        address: node.address || '',
        floor: node.floor || 1,
        lastEditedById: userId || '',
        notes: node.notes || '',
        switchCount: node.switches?.length || 0,
        portCount: node.switches?.[0]?.numberOfPorts || 0,
    });
    const [message, setMessage] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedNode({ ...editedNode, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const currentSwitchCount = node.switches.length;
            const newSwitchCount = editedNode.switchCount;
            const currentPortCount = node.switches[0].numberOfPorts;
            const newPortCount = editedNode.portCount;

            if (newSwitchCount < currentSwitchCount) {
                const switchesToRemove = node.switches.slice(newSwitchCount);
                const hasTakenPorts = switchesToRemove.some(switchObj =>
                    switchObj.ports.some(port => port.connectedEntityId !== null)
                );
                if (hasTakenPorts) {
                    setMessage({
                        type: 'warning',
                        message: 'Switch antall kan ikke reduseres fordi porter på disse er i bruk'
                    });
                    return;
                }
            }
            if (newPortCount < currentPortCount) {
                const allPorts = node.switches.flatMap(switchObj => switchObj.ports);
                const portsToRemove = allPorts.slice(newPortCount, currentPortCount);
                const hasTakenPorts = portsToRemove.some(port => port.connectedEntityId !== null);
                if (hasTakenPorts) {
                    setMessage({
                        type: 'warning',
                        message: 'Port antall kan ikke reduseres fordi noen porter er i bruk'
                    });
                    return;
                }
            }

            const updatedData = {
                name: newName === '' ? node.name : newName,
                status: editedNode.status,
                nodeType: editedNode.nodeType,
                address: editedNode.address,
                floor: editedNode.floor,
                lastEditedById: userId,
                notes: editedNode.notes,
                switchCount: editedNode.switchCount,
                portCount: editedNode.portCount
            };

            await Update(node.id, apiUrls.put.updateNodeInfo, updatedData);
            await HandleNotification(node, 'Endret informasjonen til noden', node.name);
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    };

    useEffect(() => {
        setEditedNode((prev) => ({
            ...prev,
            name: node.name || prev.name,
            status: node.status || prev.status,
            nodeType: node.nodeType || prev.nodeType,
            address: node.address || prev.address,
            floor: node.floor || prev.floor,
            notes: node.notes || prev.notes,
            switchCount: node.switches?.length ?? prev.switchCount,
            portCount: node.switches?.[0]?.numberOfPorts ?? prev.portCount,
        }));
    }, [node]);

    return (
        <div className="info-page--content-data">
            {message && (
                <InfoMessage type={message.type} message={message.message}/>
            )}
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <div className="flex--row justify-content-sb info-page--edit-info-row">
                    <div className="flex--column info-page--edit-info-row-input">
                        <label>Status</label>
                        <select
                            name="status"
                            id="status"
                            value={editedNode.status}
                            onChange={handleChange}
                        >
                            {nodeStatuses.nodeStatusOptions.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex--column info-page--edit-info-row-input">
                        <label>Antall Switcher</label>
                        <input
                            type="number"
                            min={1}
                            name="switchCount"
                            id="switchCount"
                            value={editedNode.switchCount}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="flex--row justify-content-sb info-page--edit-info-row">
                    <div className="flex--column info-page--edit-info-row-input">
                        <label>Type</label>
                        <select
                            name="nodeType"
                            id="nodeType"
                            value={editedNode.nodeType}
                            onChange={handleChange}
                        >
                            {nodeTypes.nodeTypes.map((option, index) => (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex--column info-page--edit-info-row-input">
                        <label>Antall Porter</label>
                        <select
                            name="portCount"
                            id="portCount"
                            value={editedNode.portCount}
                            onChange={handleChange}
                        >
                            <option value={24}>24</option>
                            <option value={48}>48</option>
                        </select>
                    </div>
                </div>


                <div className="flex--row justify-content-sb info-page--edit-info-row">
                    <div className="flex--column info-page--edit-info-row-input">
                        <label htmlFor="address">Adresse</label>
                        <input
                            type="text"
                            min={1}
                            id="address"
                            name="address"
                            value={editedNode.address}
                            onChange={handleChange}
                                />
                            </div>
                            <div className="flex--column info-page--edit-info-row-input">
                                <label htmlFor="floor">Etasje</label>
                                <input
                                    type="number"
                                    id="floor"
                                    name="floor"
                                    value={editedNode.floor}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <label htmlFor="notes">Notat</label>
                        <input
                            type="text"
                            min={1}
                            id="notes"
                            name="notes"
                            className="wide-input"
                            value={editedNode.notes}
                            onChange={handleChange}
                            placeholder="Viktig tilleggsinformasjon"
                        />

                        <div className="flex--row justify-content-center align-items--center">
                            <button
                                className="btn btn-secondary-white-bg"
                                type="submit"
                            >
                                Lagre Endringer
                            </button>
                        </div>
            </form>
        </div>
);
}
export default InfoPageEditNodeContents;