import { useParams } from 'react-router-dom';
import HeaderLoggedIn from "../../../components/headers/headerLoggedIn";
import React, {useEffect, useState} from "react";
import InfoPageHeader from "../../../components/headers/InfoPageHeader";
import GetById from "../../../functions/apiCalls/Get/GetById";
import MiniMap from "../../../components/maps/MiniMap";
import apiUrls from "../../../configs/api";
import InfoPageHomeownerAssocContents
    from "../../../components/displays/info-pages/InfoPageHomeownerAssocContents";
import styles from "../../../components/iconsAndStyles/Styles";
import InfoPageEditHeader from "../../../components/inputsSelectsCheckboxes/InfoPageEditHeader";
import InfoPageEditHomeownerAssocContents
    from "../../../components/displays/info-pages/edit/InfoPageEditHomeownerAssocContents";
import HeaderButtons from "../../../components/buttons/HeaderButtons";
import To404 from "../../../functions/redirects/To404";
import GetUserType from '../../../functions/apiCalls/Get/GetUserType';

const HomeownerAssocInfoPage = () => {
    const { id } = useParams();
    const userId = sessionStorage.getItem('userId');
    const [homeownerAssoc, setHomeownerAssocData] = useState(null);
    const [editLock, setEditLock] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState('');
    const homeownerAssocsStyle = styles['homeownerAssocs'];
    const [userType, setUserType] = useState(null);
    const [icon, setIcon] = useState("");

    useEffect(() => {
        const fetchUserType = async () => {
            const userType = await GetUserType();
            setUserType(userType);
        };
        fetchUserType();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    const data = await GetById(apiUrls.get.homeownerAssocs, id);
                    if (data) {
                        setHomeownerAssocData(data);
                        setEditLock(data.properties.editLock);
                        setIcon(data.properties.netadminData ? 'homeownerassoc-netadmin' : 'homeownerassoc');
                    }
                    else {
                        To404();
                    }
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [id, editMode, editLock]);


    useEffect(() => {
        document.body.classList.add("overflow-hidden");
        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, []);

    return (
        <div>
            {homeownerAssoc && (
                <>
                    <HeaderLoggedIn/>
                    <div className="info-page flex--row">
                        <div className="info-page--map">
                            <MiniMap marker={homeownerAssoc} icon={icon} style={homeownerAssocsStyle} zoom={16}/>
                        </div>
                        <div className="info-page--content flex--column w-75vw">
                            <div className="info-page--content-header flex--row justify-content-sb align-items--center">
                                {homeownerAssoc.properties && (
                                    (!editMode || homeownerAssoc.properties.netadminData) ? (
                                        <InfoPageHeader
                                            name={homeownerAssoc.properties.name}
                                            icon={icon}
                                        />
                                    ) : (
                                        <InfoPageEditHeader
                                            name={homeownerAssoc.properties.name}
                                            icon={icon}
                                            setNewName={setNewName}
                                        />
                                    )
                                )}
                                {userType !== 'Read Only' && (
                                    <HeaderButtons
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        editLock={editLock}
                                        setEditLock={setEditLock}
                                        userId={userId}
                                        userType={userType}
                                        item={homeownerAssoc}
                                        apiUrlLock={apiUrls.put.updateEditLockHomeownerAssoc}
                                        apiUrlDelete={apiUrls.delete.deleteHomeownerAssoc}
                                    />
                                )}
                            </div>
                            {!editMode ? (
                                <InfoPageHomeownerAssocContents homeownerAssoc={homeownerAssoc}
                                                                setHomeownerAssocData={setHomeownerAssocData} userType={userType}/>
                            ) : (
                                <InfoPageEditHomeownerAssocContents homeownerAssoc={homeownerAssoc} newName={newName}
                                                                    setEditMode={setEditMode}/>
                            )}
                        </div>
                    </div>
                </>
            )}

        </div>
    );
}

export default HomeownerAssocInfoPage;