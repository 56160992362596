import React, {useEffect, useState} from "react";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import InfoPageLog from "../../containers/infoPage/InfoPageLog";
import InfoPageData from "../../containers/infoPage/InfoPageData";
import DefaultNodeImage from "../../../assets/images/NodeDefaultImage.png";
import InfoPageFiles from "../../containers/infoPage/InfoPageFiles";
import InfoPageConnections from "../../containers/infoPage/InfoPageConnections";

const InfoPageNodeContents = ({node, userType}) => {
    const [homeownerAssocs, setHomeownerAssocs] = useState([]);
    const [cables, setCables] = useState([]);
    const [imageData, setImageData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (node && node.homeownerAssocs && node.homeownerAssocs.length > 0) {
                    const nodeHomeownerAssocIds = node.homeownerAssocs.map(nodeHomeownerAssoc => nodeHomeownerAssoc.lineId);
                    const allHomeownerAssocs = await GetAll(apiUrls.get.homeownerAssocs);
                    const filteredHomeownerAssocs = allHomeownerAssocs.filter(homeownerAssoc =>
                        nodeHomeownerAssocIds.includes(homeownerAssoc.id)
                    );
                    setHomeownerAssocs(filteredHomeownerAssocs);
                }
                if (node && node.cables && node.cables.length > 0) {
                    const nodeCableIds = node.cables.map(nodeCable => nodeCable.lineId);
                    const allCables = await GetAll(apiUrls.get.cables);
                    const filteredCables = allCables.filter(cable =>
                        nodeCableIds.includes(cable.id)
                    );
                    setCables(filteredCables);
                }
                if (node && node.imageData) {
                    setImageData(node.imageData);
                }
            } catch (error) {
                console.error("Error fetching node data:", error);
            }
        };
        fetchData();
    }, [node, setImageData]);

    return (
        <div className="info-page--content-data">
            <InfoPageLog item={node}/>

            <div className="flex--row justify-content-sb">
                <InfoPageData
                    headers={["Status", "Type", "Adresse", "Breddegrad", "Lengegrad", "Notater"]}
                    data={[
                        node.status,
                        node.nodeType,
                        `${node.address}, ${node.floor}. etasje`,
                        node.coordinates.latitude,
                        node.coordinates.longitude,
                        node.notes ? node.notes : 'Ingen notater',
                    ]}
                />

                <InfoPageFiles
                    item={node}
                    hasImages={true}
                    id={node.id}
                    editorLockUserId={node.editorLockUserId}
                    userType={userType}
                    DefaultImage={DefaultNodeImage}
                />
            </div>

            <InfoPageConnections
                item={node}
                headers={["Borettslag", "Kabler"]}
                data={[
                    {array: homeownerAssocs, message: "Ingen borettslag koblet til denne noden"},
                    {array: cables, message: "Ingen kabler som går til/gjennom denne noden"}
                ]}
                type='node'
            />
        </div>
    );
}
export default InfoPageNodeContents;