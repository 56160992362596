import React, {useEffect, useState} from 'react';
import apiUrls from "../../../../configs/api";
import InfoMessages from "../../../messagesAndPopups/InfoMessages";
import Post from "../../../../functions/apiCalls/Post/Post";

const SendFeedbackForm = () => {
    const [formData, setFormData] = useState({
        version: '',
        type: 'velg',
        message: '',
    });
    const [messageData, setMessageData] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    useEffect(() => {
        fetch('version.txt')
            .then(response => response.text())
            .then(data => {
                setFormData(prevState => ({
                    ...prevState,
                    version: data.trim()
                }));
            })
            .catch(error => console.error('Error fetching version:', error));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!formData.type || formData.type === "velg" || !formData.message) {
                setMessageData({
                    type: 'warning',
                    message: 'Du må fylle inn alle felt!'
                });
                return;
            }

            const response = await Post(apiUrls.post.createFeedback, formData);
            if (response) {
                setFormData({
                    type: 'velg',
                    message: '',
                });

                setMessageData({
                    type: 'success',
                    message: 'Tilbakemelding sendt, takk :)'
                });
            } else {
                setMessageData({
                    type: 'error',
                    message: 'En feil oppsto, prøv igjen.'
                });
            }
        } catch (error) {
            setMessageData({
                type: 'error',
                message: 'En feil oppsto, prøv igjen.'
            });
        }
    };

    const placeholderText = "OBOS Nett Spots er helt fantastisk og har gjort min arbeidsdag mye bedre. Livet mitt ble helt forandret etter jeg fikk tilgang på denne applikasjonen. Kommer til å gråte om vi ikke lengre får brukt Spots!";

    return (
        <div className="profile--display-page higher flex--column">
            {messageData && (
                <div className="flex--row align-items--center profile--display-header">
                    <InfoMessages type={messageData.type} message={messageData.message}/>
                </div>
            )}

            <form className="flex--column profile--send-feedback-form profile--display-body" onSubmit={handleSubmit}>
                <label>
                    Type Tilbakemelding *
                </label>
                <select
                    name="type"
                    value={formData.type || ''}
                    onChange={handleChange}
                >
                    <option value="velg">Velg type tilbakemelding</option>
                    <option value="positiv">Positiv</option>
                    <option value="bug">Bug</option>
                    <option value="kritisk">Kritisk</option>
                    <option value="forslag">Forslag</option>
                </select>

                <label>
                    Tilbakemelding *
                </label>
                <textarea
                    name="message"
                    placeholder={placeholderText}
                    value={formData.message}
                    onChange={handleChange}
                />

                <button className="btn btn-secondary-white-bg m-s-auto" type="submit">
                    Send Tilbakemelding
                </button>
            </form>
        </div>
    );
};

export default SendFeedbackForm;
