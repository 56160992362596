import React, {useEffect, useState} from "react";
import apiUrls from "../../../configs/api";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import InfoPageLog from "../../containers/infoPage/InfoPageLog";
import InfoPageData from "../../containers/infoPage/InfoPageData";
import DefaultKumImage from "../../../assets/images/KumDefaultImage.png";
import InfoPageConnections from "../../containers/infoPage/InfoPageConnections";
import InfoPageFiles from "../../containers/infoPage/InfoPageFiles";

const InfoPageKumContents = ({kum, userType}) => {
    const [pipes, setPipes] = useState([]);
    const [cables, setCables] = useState([]);
    const [imageData, setImageData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (kum && kum.pipes && kum.pipes.length > 0) {
                    const pipeKumIds = kum.pipes.map(pipeKum => pipeKum.lineId);
                    const allPipes = await GetAll(apiUrls.get.pipes);
                    const filteredPipes = allPipes.filter(pipe =>
                        pipeKumIds.includes(pipe.id)
                    );

                    setPipes(filteredPipes);
                }
                if (kum && kum.cables && kum.cables.length > 0) {
                    const cableKumIds = kum.cables.map(cableKum => cableKum.lineId);
                    const allCables = await GetAll(apiUrls.get.cables);
                    const filteredCables = allCables.filter(cable =>
                        cableKumIds.includes(cable.id)
                    );
                    setCables(filteredCables);
                }

                if (kum && kum.imageData) {
                    setImageData(kum.imageData);
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [kum]);

    return (
        <div className="info-page--content-data">
            <InfoPageLog item={kum}/>
            <div className="flex--row justify-content-sb">
                <InfoPageData
                    headers={["Status", "Type", "Skjøte","Breddegrad", "Lengegrad", "Notater"]}
                    data={[
                        kum.status,
                        kum.kumType,
                        kum.spliceEnclosure,
                        kum.coordinates.latitude,
                        kum.coordinates.longitude,
                        kum.notes ? kum.notes : 'Ingen notater',
                    ]}
                />

                <InfoPageFiles
                    item={kum}
                    hasImages={true}
                    id={kum.id}
                    editorLockUserId={kum.editorLockUserId}
                    userType={userType}
                    DefaultImage={DefaultKumImage}
                />
            </div>

            <InfoPageConnections
                item={kum}
                headers={["Kabler", "Rør"]}
                data={[
                    {array: cables, message: "Ingen kabler koblet til dette tilkoblingspunktet"},
                    {array: pipes, message: "Ingen rør som går til/gjennom dette tilkoblingspunktet"}
                ]}
                type='connectionPoint'
            />
        </div>
    );
}
export default InfoPageKumContents;