import {useEffect, useState} from "react";
import apiUrls from "../../../configs/api";
import GetAll from "../../../functions/apiCalls/Get/GetAll";

const SwitchDisplay = ({header, data}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [connectionData, setConnectionData] = useState([]);

    const toggleDisplay = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const fetchConnectionData = async () => {
            try {
                const homeownerAssocs = await GetAll(apiUrls.get.homeownerAssocs);
                const cables = await  GetAll(apiUrls.get.cables);

                const combinedData = [...homeownerAssocs, ...cables];

                setConnectionData(combinedData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchConnectionData();
    }, []);

    const getConnectionName = (id) => {
        const foundItem = connectionData.find(item => item.id === id);
        return foundItem ? foundItem.properties.name : 'Kan ikke laste';
    };

    return (
        <div className={`flex--column br info-page--switches ${isOpen ? 'active' : ''}`}>
            <div
                className="flex--row justify-content-sb info-page--switches-headers"
                onClick={toggleDisplay}
            >
                <p>{header}</p>
                <svg
                    className={`icon--black-chevron`}
                />
            </div>
            <div className="info-page--switches-content">
                <div>
                    {data ? (
                        data.map((switcher, index) => (
                            <div key={index} className="info-page--switches-data flex--column">
                                <p><b>Switch {index + 1}</b></p>
                                {switcher.ports.map((port, portIndex) => (
                                    portIndex % 4 === 0 && (
                                        <div key={portIndex} className="flex--row justify-content-sb m-b-10px info-page--switches-port-info">
                                            {switcher.ports.slice(portIndex, portIndex + 4).map((portSlice, sliceIndex) => (
                                                <div key={sliceIndex}>
                                                    <p key={sliceIndex}>
                                                        <svg className={portSlice.connectedEntityId === null ? 'icon--circle-green' : 'icon--circle-red'}/>
                                                        <span>{portSlice.number}.</span> {portSlice.connectedEntityId === null ? 'Ledig' : getConnectionName(portSlice.connectedEntityId)}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    )
                                ))}
                            </div>
                        ))
                    ) : (
                        <p>Denne noden har ingen switcher.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SwitchDisplay;