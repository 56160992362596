import React, {useEffect, useRef, useState} from "react";
import apiUrls from "../../../configs/api";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import Post from "../../../functions/apiCalls/Post/Post";
import HandleNotification from "../../../functions/notifications/HandleNotification";
import Delete from "../../../functions/apiCalls/Delete";
import FullScreenImage from "./FullScreenImage";
import FullScreenPdf from "./FullScreenPdf";
import InfoMessage from "../../messagesAndPopups/InfoMessages";
import pica from 'pica';

const InfoPageFiles = ({ item, editLock, editorLockUserId, userType, DefaultImage, hasImages }) => {
    const userId = sessionStorage.getItem("userId");
    const [pdfs, setPdfs] = useState([]);
    const [updated, setUpdated] = useState(0);
    const [displayedPdfIndex, setDisplayedPdfIndex] = useState(0);
    const [pdfFullScreenMode, setPdfFullScreenMode] = useState(false);
    const [images, setImages] = useState([]);
    const [imageFullScreenMode, setImageFullScreenMode] = useState(false);
    const [displayedImageIndex, setDisplayedImageIndex] = useState(0);
    const imageRef = useRef(null);
    const buttonDisabled = editLock && !(userId === editorLockUserId || userType === "Admin" || userType === "Utvikler");
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const fetchFiles = async () => {
            const imagesEndpoint = `${apiUrls.get.imagesAllForObject}/${item.id}`;
            const images = await GetAll(imagesEndpoint);
            if (images) {
                setImages(images);
                if (images.length === 0) {
                    setImageFullScreenMode(false);
                } else if (displayedImageIndex >= images.length) {
                    setDisplayedImageIndex(0);
                }
            }
            const pdfsEndpoint = `${apiUrls.get.pdfsAllForObject}/${item.id}`;
            const pdfs = await GetAll(pdfsEndpoint);
            if (pdfs) {
                setPdfs(pdfs);
                if (pdfs.length === 0) {
                    setPdfFullScreenMode(false);
                } else if (displayedPdfIndex >= pdfs.length) {
                    setDisplayedPdfIndex(0);
                }
            }
        };
        fetchFiles();
    }, [item, updated]);

    const handleUploadFile = async (event, fileType) => {
        let file = event.target.files[0];
        if (!file) return;
        if (file.size > 500000) {
            console.log("Endrer bilde størrelse. Tidligere bilde størrelse: " + file.size);
            const resizedImage = await resizeImage(file);
            if (!resizedImage) return;
            file = resizedImage;
        }
        console.log("Ny bilde størrelse: " + file.size);
        const reader = new FileReader();
        reader.onloadend = async () => {
            try {
                const arrayBuffer = reader.result;
                const fileData = new Uint8Array(arrayBuffer);

                let binaryString = '';
                for (let i = 0; i < fileData.length; i++) {
                    binaryString += String.fromCharCode(fileData[i]);
                }
                const base64FileData = btoa(binaryString);
                const fileName = file.name.slice(0, -4);

                let postDto = {};
                let apiUrl = '';
                let notificationMessage = '';

                if (fileType === 'image') {
                    postDto = {
                        objectId: item.id,
                        imageData: base64FileData,
                    };
                    apiUrl = apiUrls.post.createImage;
                    notificationMessage = "La til bilde på";
                } else if (fileType === 'pdf') {
                    postDto = {
                        objectId: item.id,
                        fileName: fileName,
                        fileData: base64FileData,
                    };
                    apiUrl = apiUrls.post.createPdf;
                    notificationMessage = "La til pdf på";
                }
                const response = await Post(apiUrl, postDto);
                if (response.ok) {
                    await HandleNotification(
                        item,
                        notificationMessage,
                        item.properties ? item.properties.name : item.name
                    );
                    setUpdated(Math.random());
                    setMessage({
                        type: "success",
                        message: "Bilde lastet opp sukessfult."
                    })
                    event.target.value = '';
                } else {
                    const errorText = await response.text();
                    console.error("Error details:", errorText);
                    setMessage({
                        type: "error",
                        message: "Et problem oppsto under opplastningen av bildet."
                    })
                }
            } catch (error) {
                console.error(`Error processing ${fileType} upload:`, error);
                setMessage({
                    type: "error",
                    message: "Et problem oppsto under opplastningen av bildet."
                })
            }
        };
        reader.onerror = (error) => {
            console.error("Error reading file:", error);
            setMessage({
                type: "error",
                message: "Et problem oppsto under opplastningen av bildet."
            })
        };
        reader.readAsArrayBuffer(file);
    };

    const resizeImage = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            const reader = new FileReader();
            reader.onload = (e) => {
                img.src = e.target.result;
            };
            reader.onerror = (err) => {
                reject('Error reading file');
            };
            reader.readAsDataURL(file);
            img.onload = () => {
                let maxWidth = 900;
                let maxHeight = 900;
                let width = img.width;
                let height = img.height;
                const checkSizeAndReduce = () => {
                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
                        if (resizedFile.size > 500000) {
                            console.log('File size still too large, reducing dimensions...');
                            maxWidth -= 100;  // Reduce width by 100px
                            maxHeight -= 100; // Reduce height by 100px
                            width = img.width;
                            height = img.height;
                            checkSizeAndReduce();
                        } else {
                            resolve(resizedFile);
                        }
                    }, 'image/jpeg');
                };
                checkSizeAndReduce();
            };
            img.onerror = () => {
                reject('Error loading image');
            };
        });
    };

    const handleFileDelete = async (index, id, fileType) => {
        try {
            let apiUrl = '';
            let notificationMessage = '';
            if (fileType === 'image') {
                apiUrl = apiUrls.delete.deleteImage;
                notificationMessage = "Fjernet bilde fra";
            } else if (fileType === 'pdf') {
                apiUrl = apiUrls.delete.deletePdf;
                notificationMessage = "Fjernet pdf fra";
            }
            let response = await Delete(apiUrl, id);
            if (response) {
                if (fileType === 'image') {
                    if (index !== 0) {
                        setDisplayedImageIndex(index - 1);
                    } else {
                        setDisplayedImageIndex(0);
                    }
                } else if (fileType === 'pdf') {
                    const newIndex = pdfs.length === 1 ? 0 : Math.min(index, pdfs.length - 2);
                    setDisplayedPdfIndex(newIndex);

                    if (pdfs.length <= 1) {
                        setPdfFullScreenMode(false);
                    }
                }
                setUpdated(Math.random());
                await HandleNotification(item, notificationMessage, item.properties ? item.properties.name : item.name);
            } else {
                console.error(`Failed to delete ${fileType}`);
            }
        } catch (error) {
            console.error(`Error deleting ${fileType}:`, error);
        }
    };

    const getImageSrc = (imageData) => {
        return `data:image/png;base64,${imageData}`;
    };

    const downloadPdf = (pdf) => {
        const fileName = pdf.fileName + ".pdf";
        const fileData = pdf.fileData;
        const byteCharacters = atob(fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleFullScreen = (type) => {
        if (type === 'image') {
            setImageFullScreenMode(prevState => !prevState);
        }
        else {
            setPdfFullScreenMode(prevState => !prevState);
        }
    }

    useEffect(() => {
        if (message && message.type === "success") {
            const timeoutId = setTimeout(() => {
                setMessage(null);
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [message]);

    return (
        <div className="flex--column">
            {message && (
                <InfoMessage message={message.message} type={message.type}/>
            )}
            <h3>Filer</h3>
            <div className={`info-page--files ${hasImages ? '' : 'noImages'} flex--column justify-content-sb align-items--center`}>
                {hasImages && (
                    <div className="info-page--files-image flex--column justify-content-center align-items--center">
                        <p>Bilder</p>
                        {images && images.length > 0 ? (
                            <img
                                ref={imageRef}
                                src={getImageSrc(images[displayedImageIndex].imageData)}
                                alt="Bilde av kum"
                            />
                        ) : (
                            <img ref={imageRef} src={DefaultImage} alt="Default Bilde"/>
                        )}

                        <div
                            className={`info-page--files-image-buttons flex--row justify-content-sb align-items--center`}>
                            <svg
                                className={`icon--blue-chevron-l ${(displayedImageIndex === 0 || images.length === 0) ? "disabled" : ""} chevron blue-svg`}
                                onClick={displayedImageIndex === 0 ? null : () => setDisplayedImageIndex((prevIndex) => (prevIndex - 1) % images.length)}
                            />

                            {userType !== "Read Only" && (
                                <div className="flex--row align-items--center justify-content-center">
                                    <label htmlFor="file-upload" className="image-add">
                                        <svg
                                            className={`icon--plus-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                        />
                                        <input
                                            id="file-upload"
                                            className="m-s-auto"
                                            type="file"
                                            onChange={buttonDisabled ? null : (e) => handleUploadFile(e, 'image')}
                                            accept="image/*"
                                            disabled={buttonDisabled}
                                        />
                                    </label>
                                    {images.length > 0 && (
                                        <>
                                            <svg
                                                className="blue-svg icon--blue-expand"
                                                onClick={() => handleFullScreen('image')}
                                            />
                                            <label htmlFor="file-delete" className="image-delete">
                                                <svg
                                                    className={`icon--trash-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                                    onClick={buttonDisabled ? null : () => handleFileDelete(displayedImageIndex, images[displayedImageIndex].id, 'image')}
                                                />
                                            </label>
                                        </>
                                    )}
                                </div>
                            )}

                            <svg
                                className={`icon--blue-chevron-r ${(displayedImageIndex === images.length - 1 || images.length === 0) ? "disabled" : ""} chevron blue-svg`}
                                onClick={displayedImageIndex === images.length - 1 ? null : () => setDisplayedImageIndex((prevIndex) => (prevIndex + 1) % images.length)}
                            />
                        </div>
                    </div>
                )}

                <div className="info-page--files-pdf flex--column justify-content-center align-items--center">
                    <p>PDFer</p>
                    <div className="info-page--files-pdf-buttons flex--row justify-content-sb align-items--center">
                        <svg
                            className={`icon--blue-chevron-l ${(displayedPdfIndex === 0 || pdfs.length === 0) ? "disabled" : ""} chevron blue-svg`}
                            onClick={displayedPdfIndex === 0 ? null : () => setDisplayedPdfIndex((prevIndex) => (prevIndex - 1 + pdfs.length) % pdfs.length)}
                        />

                        <div
                            className={`flex--row justify-content-sb align-items--center`}>
                            {pdfs.length > 0 ? (
                                <label className="fileName">{pdfs[displayedPdfIndex].fileName}</label>
                            ) : (
                                <label className="fileName">Ingen PDF lagt til</label>
                            )}

                            <div className="flex--row justify-content-sb align-items--center">
                                {userType !== "Read Only" && (
                                    <>
                                        <label htmlFor="pdf-upload">
                                            <svg
                                                className={`icon--plus-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                            />
                                            <input
                                                id="pdf-upload"
                                                type="file"
                                                onChange={buttonDisabled ? null : (e) => handleUploadFile(e, 'pdf')}
                                            />
                                        </label>
                                        {pdfs.length > 0 && (
                                            <label htmlFor="pdf-delete" className="image-delete">
                                                <svg
                                                    className={`icon--trash-blue ${buttonDisabled ? "disabled" : ""} flex--column align-items--center`}
                                                    onClick={buttonDisabled ? null : () => handleFileDelete(displayedPdfIndex, pdfs[displayedPdfIndex].id, 'pdf')}
                                                />
                                            </label>
                                        )}
                                    </>
                                )}
                                {pdfs.length > 0 && (
                                    <>
                                        <svg
                                            className="icon--blue-expand blue-svg"
                                            onClick={() => handleFullScreen('pdf')}
                                        />
                                        <label htmlFor="pdf-download" className="image-download">
                                            <svg
                                                className="icon--blue-download blue-svg flex--column align-items--center"
                                                onClick={() => downloadPdf(pdfs[displayedPdfIndex])}
                                            />
                                        </label>
                                    </>
                                )}
                            </div>
                        </div>

                        <svg
                            className={`icon--blue-chevron-r ${(displayedPdfIndex === pdfs.length - 1 || pdfs.length === 0) ? "disabled" : ""} chevron blue-svg`}
                            onClick={displayedPdfIndex === pdfs.length - 1 ? null : () => setDisplayedPdfIndex((prevIndex) => (prevIndex + 1) % pdfs.length)}
                        />
                    </div>

                </div>
            </div>

            {imageFullScreenMode && (
                <FullScreenImage
                    item={item}
                    images={images}
                    displayedImageIndex={displayedImageIndex}
                    setDisplayedImageIndex={setDisplayedImageIndex}
                    imageRef={imageRef}
                    DefaultImage={DefaultImage}
                    handleFullScreen={() => handleFullScreen('image')}
                    handleFileUpload={(e) => handleUploadFile(e, 'image')}
                    handleImageDelete={() => handleFileDelete(displayedImageIndex, images[displayedImageIndex].id, 'image')}
                    userType={userType}
                />
            )}

            {pdfFullScreenMode && pdfs.length > 0 && (
                <FullScreenPdf
                    item={item}
                    userType={userType}
                    pdfs={pdfs}
                    downloadPdf={downloadPdf}
                    buttonDisabled={buttonDisabled}
                    displayedPdfIndex={displayedPdfIndex}
                    setDisplayedPdfIndex={setDisplayedPdfIndex}
                    handleFullScreen={() => handleFullScreen('pdf')}
                    handleFileUpload={(e) => handleUploadFile(e, 'pdf')}
                    handleFileDeletion={() => handleFileDelete(displayedPdfIndex, pdfs[displayedPdfIndex].id, 'pdf')}
                />
            )}
        </div>
    );
}
export default InfoPageFiles;