import React, { useState } from 'react';
import ViewFeedback from "./developer/ViewFeedback";
import AddUpdate from "./developer/AddUpdate";

const DeveloperDisplay = () => {
    const [activeForm, setActiveForm] = useState('seeFeedback');

    const toggleForm = (form) => {
        setActiveForm(prevForm => (prevForm === form ? 'seeFeedback' : form));
    };

    return (
        <div className="profile--display-page  flex--column">
            <div
                className="flex--row align-items--center justify-content-sb profile--display-header profile--developer-nav">
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'seeFeedback' ? 'active' : ''}`}
                    onClick={() => toggleForm('seeFeedback')}
                >
                    Se tilbakemeldinger
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'addUpdate' ? 'active' : ''}`}
                    onClick={() => toggleForm('addUpdate')}
                >
                    Ny Oppdatering
                </button>
            </div>
            <div>
                {activeForm === 'seeFeedback' && <ViewFeedback/>}
                {activeForm === 'addUpdate' && <AddUpdate/>}
            </div>
        </div>
    );
};

export default DeveloperDisplay;