import React, {useEffect, useState} from 'react';
import CheckboxFilter from "../inputsSelectsCheckboxes/ChechboxFilter";
import Update from "../../functions/apiCalls/Update/Update";
import apiUrls from "../../configs/api";
import GetById from "../../functions/apiCalls/Get/GetById";

const FilterLayers = ({ filters, setFilters }) => {
    const userId = sessionStorage.getItem('userId');
    const [checkboxesClosed, setCheckboxesClosed] = useState({
        kummer: true,
        noder: true,
        cables: true,
        pipes: true,
        homeownerAssocs: true,
        all: true
    });

    const handleCheckboxChange = async (event, layerType, saveable) => {
        const { name, checked } = event.target;
        let updatedFilters;

        if (!checked && saveable && saveable === true) {
            try {
                let formData = {
                    preference: `${layerType}_${name}`
                };
                await Update(userId, apiUrls.put.addFilterPreferenceToUser, formData);
            } catch (error) {
                console.error('Error updating filter preference:', error);
            }
        }
        if (checked && saveable && saveable === true) {
            try {
                let formData = {
                    preference: `${layerType}_${name}`
                };
                await Update(userId, apiUrls.put.removeFilterPreferenceToUser, formData);
            } catch (error) {
                console.error('Error removing filter preference:', error);
            }
        }
        if (name === 'all') {
            const groupFilter = filters[layerType];
            const groupKeys = Object.keys(groupFilter);

            const updatedGroupFilter = groupKeys.reduce((acc, key) => {
                if (key !== 'all') {
                    acc[key] = checked;
                }
                return acc;
            }, {});

            updatedFilters = {
                ...filters,
                [layerType]: {
                    ...groupFilter,
                    ...updatedGroupFilter,
                    all: checked,
                },
            };
        } else {
            updatedFilters = {
                ...filters,
                [layerType]: {
                    ...filters[layerType],
                    [name]: checked,
                },
            };
        }
        setFilters(updatedFilters);
    };

    const toggleCloseClass = (checkboxName) => {
        setCheckboxesClosed((prevStatus) => ({
            ...prevStatus,
            [checkboxName]: !prevStatus[checkboxName]
        }));
    };

    useEffect(() => {
        const fetchUserPreferences = async () => {
            try {
                const user = await GetById(apiUrls.get.users, userId);
                if (user && user.filterPreferences) {
                    user.filterPreferences.forEach(preference => {
                        const [layerType, name] = preference.split('_');
                        setFilters(prevFilters => {
                            const updatedFilters = { ...prevFilters };
                            if (updatedFilters[layerType] && updatedFilters[layerType][name] !== undefined) {
                                updatedFilters[layerType][name] = false;
                                if (name === "all") {
                                    const groupFilter = updatedFilters[layerType];
                                    Object.keys(groupFilter).forEach(key => {
                                        if (key !== 'all') {
                                            groupFilter[key] = false;
                                        }
                                    });
                                }
                            }
                            return updatedFilters;
                        });
                    });
                }
            } catch (error) {
                console.error('Error fetching user preferences:', error);
            }
        };
        fetchUserPreferences();
    }, []);

    return (
        <>
            <h3>Filtrer</h3>
            <div className="map--filter-checkboxes-container">
                <div className="filter--checkboxes">
                    <CheckboxFilter
                        name="kummer"
                        header="Tilkoblingspunkter"
                        options={[
                            {name: 'all', label: 'Alle', saveable: true},
                            {name: 'status', label: 'I Bruk'},
                            {name: 'tempClosed', label: 'Midlertidlig Stengt'},
                            {name: 'kum', label: 'Kummer'},
                            {name: 'wallLocker', label: 'Veggskap'},
                            {name: 'spliceEnclosureB5', label: 'B5 Skjøte'},
                            {name: 'spliceEnclosureB4', label: 'B4 Skjøte'},
                            {name: 'spliceEnclosureNone', label: 'Ingen Skjøte'},
                        ]}
                        filters={filters}
                        checkboxesClosed={checkboxesClosed}
                        toggleCloseClass={toggleCloseClass}
                        handleCheckboxChange={handleCheckboxChange}
                    />

                    <CheckboxFilter
                        name="noder"
                        header="Noder"
                        options={[
                            {name: 'all', label: 'Alle', saveable: true},
                            {name: 'status', label: 'I Bruk'},
                            {name: 'available', label: 'Ingen Ledige Porter'},
                            {name: 'tempClosed', label: 'Midlertidlig Stengt'},
                            {name: 'dist', label: 'Dist (Grønn)'},
                            {name: 'node', label: 'Node (Blå)'}
                        ]}
                        filters={filters}
                        checkboxesClosed={checkboxesClosed}
                        toggleCloseClass={toggleCloseClass}
                        handleCheckboxChange={handleCheckboxChange}
                    />

                    <CheckboxFilter
                        name="cables"
                        header="Kabler"
                        options={[
                            {name: 'all', label: 'Alle', saveable: true},
                            {name: 'status', label: 'I Bruk'},
                            {name: 'tempClosed', label: 'Midlertidlig Stengt'},
                            {name: 'icons', label: 'Ikoner', saveable: true}
                        ]}
                        filters={filters}
                        checkboxesClosed={checkboxesClosed}
                        toggleCloseClass={toggleCloseClass}
                        handleCheckboxChange={handleCheckboxChange}
                    />

                    <CheckboxFilter
                        name="pipes"
                        header="Rør"
                        options={[
                            {name: 'all', label: 'Alle', saveable: true},
                            {name: 'status', label: 'I Bruk'},
                            {name: 'tempClosed', label: 'Midlertidlig Stengt'},
                            {name: 'full', label: 'Fullt'},
                            {name: 'icons', label: 'Ikoner', saveable: true}
                        ]}
                        filters={filters}
                        checkboxesClosed={checkboxesClosed}
                        toggleCloseClass={toggleCloseClass}
                        handleCheckboxChange={handleCheckboxChange}
                    />

                    <CheckboxFilter
                        name="homeownerAssocs"
                        header="Borettslag"
                        options={[
                            {name: 'all', label: 'Alle', saveable: true},
                            {name: 'operational', label: 'I Drift'},
                            {name: 'completed', label: 'Ferdig stilt'},
                            {name: 'beingBuilt', label: 'Bygges'},
                            {name: 'inPlanning', label: 'Under Planlegging'},
                            {name: 'allConnectedUnits', label: 'Alle Leiligheter Tilkoblet'},
                            {name: 'unconnectedUnits', label: 'Ikke-tilkoblede Leiligheter'},
                            {name: 'icons', label: 'Ikoner', saveable: true}
                        ]}
                        filters={filters}
                        checkboxesClosed={checkboxesClosed}
                        toggleCloseClass={toggleCloseClass}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                </div>

                <CheckboxFilter
                    name="all"
                    header="Alle"
                    options={[
                        {name: 'drawing', label: 'Kladd', saveable: true},
                        {name: 'beingBuilt', label: 'Bygges / Under planlegging'},
                        {name: 'tempClosed', label: 'Midlertidig stengt/Under vedlikehold'},
                        {name: 'locked', label: 'Låste'},
                        {name: 'icons', label: 'Ikoner', saveable: true}
                    ]}
                    filters={filters}
                    checkboxesClosed={checkboxesClosed}
                    toggleCloseClass={toggleCloseClass}
                    handleCheckboxChange={handleCheckboxChange}
                />
            </div>
        </>

    );
};

export default FilterLayers;

