const GetById = async (endpoint, id) => {
    try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${endpoint}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            if (response.status === 404) return null; // Ignore 404
            console.warn(`Request failed with status: ${response.status}`);
            return null;
        }

        return await response.json();
    } catch (error) {
        console.warn(`Fetch error (possibly network issue): ${error.message}`);
        return null;
    }
};

export default GetById;

