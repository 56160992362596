import {useEffect, useState} from "react";

const CustomSelect = ({
                          searchInput,
                          setSearchInput,
                          searchResults,
                          setSearchResults,
                          searchData,
                          name,
                          handleNodeChange,
                      }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        setSearchResults(searchData)
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            setSearchInput("");
            setSearchResults(searchData);
        }
    };

    const handleSelect = (item) => {
        setSearchInput(item.label);
        setShowDropdown(false);
        handleNodeChange(name, item.value);
    };

    const handleSearchInputChange = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input);
        if (input === "") {
            setSearchResults(searchData);
        } else {
            const filtered = searchData.filter((item) =>
                item.label.toLowerCase().includes(input)
            );
            setSearchResults(filtered);
        }
    };

    return (
        <div className="custom-select flex--row align-items--center">
            <input
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
                onFocus={() => {
                    setShowDropdown(true);
                }}
                onKeyDown={handleKeyDown}
                placeholder="Søk..."
                className="search-input"
            />
            {showDropdown && (
                <div className="dropdown-list">
                    {searchResults.length === 0 ? (
                        <p>No results found</p>
                    ) : (
                        searchResults.map((item, index) => (
                            <p
                                key={index}
                                className={`dropdown-item ${item.taken || item.full || item.tempClosed ? "disabled" : ""}`}
                                onMouseDown={() => !(item.taken || item.full || item.tempClosed) && handleSelect(item)}
                            >
                                {item.label}
                            </p>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};
export default CustomSelect;