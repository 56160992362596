import React, {useEffect, useState} from 'react';
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import InfoPageLog from "../../containers/infoPage/InfoPageLog";
import InfoPageData from "../../containers/infoPage/InfoPageData";
import {calculateLineLength} from "../../../functions/page-spesific/infoPage/getDistanceInMeters";
import GetById from '../../../functions/apiCalls/Get/GetById';
import InfoPageFiles from "../../containers/infoPage/InfoPageFiles";
import InfoPageConnections from "../../containers/infoPage/InfoPageConnections";

const InfoPagePipeContents = ({pipe, filledStatus, userType}) => {
    const [pipeLength, setPipeLength] = useState(0);
    const [kummer, setKummer] = useState([]);
    const [cables, setCables] = useState([]);
    const [pipes, setPipes] = useState([]);
    const [inPipe, setInPipe] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (pipe && pipe.properties && pipe.properties.kummer && pipe.properties.kummer.length > 0) {
                    const pipeKumIds = pipe.properties.kummer.map(pipeKum => pipeKum.pointId);
                    const allKummer = await GetAll(apiUrls.get.kummer);
                    const filteredKummer = allKummer.filter(kum =>
                        pipeKumIds.includes(kum.id)
                    );
                    setKummer(filteredKummer);
                }
                if (pipe && pipe.properties && pipe.properties.cables && pipe.properties.cables.length > 0) {
                    const pipeCableIds = pipe.properties.cables.map(pipeCable => pipeCable);
                    const allCables = await GetAll(apiUrls.get.cables);
                    const filteredCables = allCables.filter(cable =>
                        pipeCableIds.includes(cable.id)
                    );
                    setCables(filteredCables);
                }
                if (pipe && pipe.properties && pipe.properties.pipes && pipe.properties.pipes.length > 0) {
                    const pipePipeIds = pipe.properties.pipes.map(pipePipe => pipePipe);
                    const allPipes = await GetAll(apiUrls.get.pipes);
                    const filteredPipes = allPipes.filter(pipe =>
                        pipePipeIds.includes(pipe.id)
                    );
                    setPipes(filteredPipes);
                }
                if (pipe && pipe.properties && pipe.properties.pipeInPipeId) {
                    const inPipe = await GetById(apiUrls.get.pipes, pipe.properties.pipeInPipeId);
                    setInPipe(inPipe);
                }
            } catch (error) {
                console.error('Error fetching pipe data:', error);
            }
        };
        fetchData();
    }, [pipe]);

    useEffect(() => {
        if (pipe && pipe.geometry && pipe.geometry.coordinates && pipe.geometry.coordinates.length > 0) {
            const length = calculateLineLength(pipe.geometry.coordinates);
            setPipeLength(length);
        }
    }, [pipe]);

    return (
        <div className="info-page--content-data">
            <InfoPageLog item={pipe}/>

            <div className="flex--row justify-content-sb">
                <InfoPageData
                    headers={["Status", "Fullt", "Farge", "Størrelse", "Lengde", "Start Koordinat", "Slutt Koordinat", "Notat"]}
                    data={[
                        pipe.properties.status,
                        filledStatus ? "Ja" : "Nei",
                        pipe.properties.color,
                        pipe.properties.size,
                        `${pipeLength.toFixed(0)} meter`,
                        `${pipe.geometry.coordinates[0][1]}, ${pipe.geometry.coordinates[0][0]}`,
                        `${pipe.geometry.coordinates[pipe.geometry.coordinates.length - 1][1]}, ${pipe.geometry.coordinates[pipe.geometry.coordinates.length - 1][0]}`,
                        pipe.properties.notes ? pipe.properties.notes : 'Ingen notater'
                    ]}
                />
                <InfoPageFiles
                    item={pipe}
                    hasImages={false}
                    editLock={pipe.properties.editLock}
                    editorLockUserId={pipe.properties.editorLockUserId}
                    DefaultImage={null}
                    userType={userType}
                />
            </div>

            <InfoPageConnections
                item={pipe}
                type="cable"
                headers={["I Rør", "Rør i Røret", "Kabler i Røret", "Tilkoblingspunkter"]}
                data={[
                    {array: inPipe, message: "Ikke i et rør"},
                    {array: pipes, message: "Ingen rør i dette røret"},
                    {array: cables, message: "Ingen kabler i dette røret"},
                    {array: kummer, message: "Ingen tilkoblingspunkter koblet til dette røret"}
                ]}
            />
        </div>
    );
};

export default InfoPagePipeContents;