import React, {useEffect, useState} from "react";
import Update from "../../../../functions/apiCalls/Update/Update";
import apiUrls from "../../../../configs/api";
import statusOptions from "../../../../assets/data/jsonFiles/General/statuses.json";
import HandleNotification from "../../../../functions/notifications/HandleNotification";
import GetAll from "../../../../functions/apiCalls/Get/GetAll";

const InfoPageEditCableContents = ({cable, newName, setEditMode}) => {
    const [editedCable, setEditedCable] = useState({
        name: newName,
        status: cable.properties.status,
        cableType: cable.properties.cableType,
        notes: cable.properties.notes,
    });
    const [cableTypes, setCableTypes] = useState(null);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditedCable({ ...editedCable, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const updatedData = {
                name: newName === '' ? cable.properties.name : newName,
                status: editedCable.status,
                cableType: editedCable.cableType,
                notes: editedCable.notes
            };

            await Update(cable.id, apiUrls.put.updateCableInfo, updatedData);
            await HandleNotification(cable, 'Endret informasjonen til kabelen', cable.properties.name);
            setEditMode(false);
        } catch (error) {
            console.error('Failed to update data:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const typesResponse = await GetAll(apiUrls.get.cableTypes);
                if (Array.isArray(typesResponse) && typesResponse.length > 0) {
                    setCableTypes(typesResponse);
                } else {
                    setCableTypes([]);
                }
                setEditedCable((prev) => ({
                    ...prev,
                    cableType: editedCable.cableType || typesResponse?.[0]?.name,
                }));
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        setEditedCable({ ...cable.properties });
    }, [cable]);

    return (
        <div className="info-page--content-data">
            <form className="info-page--edit-info flex--column" onSubmit={handleSubmit}>
                <label>Status</label>
                <select
                    name="status"
                    id="status"
                    value={editedCable.status}
                    onChange={handleChange}
                >

                    {statusOptions.statusOptions.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                <label>Type</label>
                <select
                    name="cableType"
                    id="cableType"
                    value={editedCable.cableType}
                    onChange={handleChange}
                >
                    {cableTypes && (
                        cableTypes.length === 0 ? (
                            <option value="">
                                Ingen typer opprettet
                            </option>
                        ) : (
                            cableTypes.map((option, index) => (
                                <option key={index} value={option.name}>
                                    {option.name}
                                </option>
                            ))
                        )
                    )}
                </select>

                <label>Notat</label>
                <input
                    type="text"
                    id="notes"
                    name="notes"
                    className="wide-input"
                    value={editedCable.notes}
                    onChange={handleChange}
                    placeholder="Viktig tilleggsinformasjon"
                />

                <div className="flex--row justify-content-center align-items--center">
                    <button
                        className="btn btn-secondary-white-bg"
                        type="submit"
                    >
                        Lagre Endringer
                    </button>
                </div>
            </form>
        </div>
    );
}
export default InfoPageEditCableContents;