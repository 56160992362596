import React, {useState} from "react";
import SendFeedbackForm from "./feedback/SendFeedbackForm";
import UserViewFeedback from "./feedback/UserViewFeedback";

const FeedbackDisplay = () => {
    const [activeForm, setActiveForm] = useState('sendFeedback');

    const toggleForm = (form) => {
        setActiveForm(prevForm => (prevForm === form ? 'sendFeedback' : form));
    };
    return (
        <div className="profile--display-page flex--column">
            <div
                className="flex--row align-items--center justify-content-se profile--display-header profile--feedback-nav">
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'sendFeedback' ? 'active' : ''}`}
                    onClick={() => toggleForm('sendFeedback')}
                >
                    Send Tilbakemelding
                </button>
                <button
                    className={`btn btn-secondary-white-bg ${activeForm === 'viewFeedback' ? 'active' : ''}`}
                    onClick={() => toggleForm('viewFeedback')}
                >
                    Dine Sendte Tilbakemeldinger
                </button>
            </div>
            <div>
                {activeForm === 'sendFeedback' && <SendFeedbackForm/>}
                {activeForm === 'viewFeedback' && <UserViewFeedback/>}
            </div>
        </div>
    );
}
export default FeedbackDisplay;