import React, {useEffect, useState} from 'react';
import InfoMessages from "../../messagesAndPopups/InfoMessages";
import GetAll from "../../../functions/apiCalls/Get/GetAll";
import apiUrls from "../../../configs/api";
import NewPipeInputs from './Inputs/PipeInputs';
import HandleInputChange from "../../../functions/inputs/HandleInputChange";

const NewPipeForm = ({ onSubmit, isAddingPipe, setIsAddingPipe, drawnCoordinates, setDrawnCoordinates }) => {
    const initialFormData = {
        name: '',
        status: 'I bruk',
        color: 'Blå',
        size: '110mm',
        pipeInPipe: 'Nei',
        selectedPipeId: '',
        notes: ''
    };
    const [formData, setFormData] = useState(initialFormData);
    const [pipes, setPipes] = useState([]);
    const [messageData, setMessageData] = useState(null);

    const handlePipeChange = (e) => {
        setFormData(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await GetAll(apiUrls.get.pipes);
            if (data) {
                setPipes(data);
            }
        };
        fetchData();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, status, color, size, pipeInPipe, selectedPipeId, notes } = formData;
        if (name && status && color && size) {
            let coordinatesToSend = [];
            let pipeData = {};

            if (pipeInPipe === 'Ja' && selectedPipeId !== '') {
                const selectedPipe = pipes.find(pipe => pipe.id === selectedPipeId);
                if (selectedPipe) {
                    coordinatesToSend = selectedPipe.geometry.coordinates.map(coord => [coord[1], coord[0]]);
                } else {
                    setMessageData({
                        type: 'warning',
                        message: 'Velg et gyldig rør å legge røret inni.'
                    });
                    return;
                }
            } else {
                if (drawnCoordinates.length >= 2) {
                    coordinatesToSend = drawnCoordinates;
                } else {
                    setMessageData({
                        type: 'warning',
                        message: 'Du må tegne inn minst 2 koordinater eller velge et rør å legge røret i!'
                    });
                    return;
                }
            }
            pipeData = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: coordinatesToSend,
                },
                properties: {
                    type: 'Rør',
                    name: name,
                    status: status,
                    color: color,
                    size: size,
                    pipeInPipe: (pipeInPipe === 'Nei' ? false : true),
                    pipeInPipeId: (pipeInPipe === 'Ja' ? selectedPipeId : null),
                    notes: notes
                },
            };
            onSubmit(pipeData);
            setFormData(initialFormData);
        } else {
            setMessageData({
                type: 'warning',
                message: 'Du må fylle inn alle felt!'
            });
        }
    };
    const handleToggleDrawing = () => {
        setIsAddingPipe(prev => !prev);
    };

    useEffect(() => {
        if (isAddingPipe) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [isAddingPipe]);

    useEffect(() => {
        if (!isAddingPipe) {
            setDrawnCoordinates([]);
        }
    }, [isAddingPipe, setDrawnCoordinates]);

    return (
        <div className="map--add-form flex--column justify-content-center p-30px">
            <div className="map--add-form-header flex--row justify-content-sb">
                <h3>Rør</h3>
                <button
                    className={`btn ${formData.pipeInPipe === 'Ja' ? 'disabled' : ''} btn-secondary-white-bg flex--row ${isAddingPipe ? 'active' : ''}`}
                    onClick={handleToggleDrawing}
                    disabled={formData.pipeInPipe === 'Ja'}
                >
                    {isAddingPipe ? (
                        <>
                            Slett Tegning <svg className="icon--green-edit"/>
                        </>
                    ) : (
                        formData.pipeInPipe === 'Ja' ? (
                            <>
                                Marker Linje <svg className="icon--green-edit"/>
                            </>
                        ): (
                            <>
                                Marker Linje <svg className="icon--white-edit"/>
                            </>
                        )
                    )}
                </button>
                <div className="helping-box helping-box--draw">
                    {isAddingPipe ? (
                        <p>Klikk for å slette markeringene gjort på kartet.</p>
                    ) : (formData.pipeInPipe === 'Ja' ? (
                        <p>Trenger ikke å tegne røret, koordinatene hentes fra røret den er inni.</p>
                    ) : (
                        <p>Klikk for å tegne røret på kartet. Høyreklikk for å avslutte tegningen uten å slette.</p>
                    ))}
                </div>
            </div>

            <form onSubmit={handleSubmit} className="flex--column justify-content-sb align-items--center">
                <div className="map--add-form-message">
                    {messageData && (
                        <InfoMessages type={messageData.type} message={messageData.message}/>
                    )}
                </div>

                <NewPipeInputs handleInputChange={HandleInputChange} handlePipeChange={handlePipeChange}
                               pipesData={pipes} formData={formData} setFormData={setFormData}/>

                <button
                    className="btn btn-secondary-white-bg"
                    type="submit"
                >
                    Opprett
                </button>
            </form>
        </div>
    );
};

export default NewPipeForm;

